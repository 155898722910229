<template>
  <div>
    <div v-if="props.children.length" class='menu-list' :style="{padding:props.boxPaddingH+'px '+props.boxPaddingV+'px'}">
      <div :style="{width:'calc(100%/'+props.rowNum+')',marginBottom:(_index+1)>(Math.ceil(props.children.length/props.rowNum)-1) * props.rowNum?'0px':'16px'}" class='menu-item' v-for="(col,_index) in props.children" :key='_index' >
        <img class='item-img' :style="{borderRadius: props.radiusNum/2+'%', width: props.size/100 +'rem',height: props.size/100 +'rem'}" v-if='col.imgUrl' :src="baseUrl + col.imgUrl" alt="" style='background:none'>
        <div class='item-img' v-else :style="{borderRadius: props.radiusNum/2+'%'}" >{{col.title.length?col.title.substr(0,1):'?'}}</div>
        <p class='item-title'>
          {{col.title||'导航'+(_index+1)}}
        </p>
      </div>
    </div>
    <div v-else>
      <no-data text='请添加图文导航'></no-data>
    </div>
  </div>
</template>
<script>
export default {
  name: 'add-img',
  props: {
    props: {
      type: Object
    }
  },
  setup (props, { root }) {
    return {
      baseUrl: process.env.VUE_APP_IMG
    }
  }
}
</script>
<style lang="less" scoped>
  .menu-list {
    background: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px 0;
    flex-wrap: wrap;
  }
  .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;
  }
  .item-title {
    font-size:14px;
    margin-top:4px;
    text-align:center;
    font-size: 12px;
    color: #666;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .item-img {
    width: 1rem;
    height: 1rem;
    background:#eee;
    display:block;
    object-fit: contain;
    text-align: center;
    line-height: 1rem;
    font-size: .4rem;
    font-weight: 900;
    color: #666;
  }
</style>
